import request from '@/utils/request'


export const  myList  = () => request.get('lpInvest/myList')

export const  add  = (data) => request.post('lpInvest/add',data)

export const  remove  = (data) => request.get('lpInvest/remove',data)

export const  config  = () => request.get('lpInvest/config')


